import './bootstrap';
import '../css/app.css';

import { DefineComponent } from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { createPinia } from 'pinia';

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: import.meta.env.VITE_APP_ENV,
  appVersion: import.meta.env.PACKAGE_VERSION,
});

const bugsnagVue = Bugsnag.getPlugin('vue');

const appName =
  window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob('./Pages/**/*.vue')
    ) as Promise<DefineComponent>,
  setup({ el, App, props, plugin }) {
    if (bugsnagVue) {
      const app = createApp({ render: () => h(App, props) })
        .component('AppLink', Link)
        .component('AppHead', Head)
        .use(bugsnagVue)
        .use(createPinia())
        .use(plugin);

      app.config.globalProperties.route = route;
      app.mount(el);
    }
  },
  progress: {
    includeCSS: false,
  },
});
